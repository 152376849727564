
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  Ref,
  reactive,
  PropType,
} from "vue";
import { useI18n } from "vue-i18n";
import { AxiosStatic } from "axios";
import { notification } from "ant-design-vue";
import { useStore } from "../store";

import { InfoCircleOutlined, MessageOutlined } from "@ant-design/icons-vue";
import AddMessage from "../components/AddMessage.vue";
import { ConversationItem, Visit } from "../../types";
import { map } from "lodash";

interface VisitExt extends Visit {
  patientAnswered: boolean;
  isExpired: boolean;
}

export const ChatComponent = defineComponent({
  props: {
    id: {
      type: Object as PropType<Visit["id"]>,
      required: true,
    },
  },
  components: {
    InfoCircleOutlined,
    MessageOutlined,
    AddMessage,
  },
  setup(props) {
    const store = useStore();
    const http = inject("http") as AxiosStatic;
    const { t, d } = useI18n();

    const messages: Ref<Array<ConversationItem>> = ref([]);
    const data: VisitExt = reactive({
      appointmentAt: new Date().toISOString(),
      contractorId: "0",
      conversationExpiresAt: new Date().toISOString(),
      doctor: "",
      isExpired: false,
      finishedAt: new Date().toISOString(),
      id: "0",
      processStatus: "",
      patientAnswered: false,
      visitType: "HOUSE",
      nfzVisit: false,
    });
    const addMessageVisible: Ref<boolean> = ref(false);
    const loadingMessages: Ref<boolean> = ref(false);
    const loadingVisit: Ref<boolean> = ref(false);

    const getMessages = async () => {
      loadingMessages.value = true;

      try {
        let conversation: Array<ConversationItem> = await http
          .get(`patient-portal/api/conversation/${props.id}`)
          .then((res) => res.data);

        conversation = map(conversation, (msg: ConversationItem) => {
          if (store.state.user.roles.includes(msg.role)) {
            data.patientAnswered = true;
          }
          return msg;
        });
        messages.value = conversation;
      } catch {
        notification.open({
          message: t("ERROR.4956"),
          class: "error",
        });
      } finally {
        loadingMessages.value = false;
      }
    };
    const afterAddMessageModalSuccess = async () => {
      await getMessages();
    };

    onMounted(async () => {
      http.put(
        `patient-portal/api/notification/deactivate?type=MESSAGE&appointmentId=${props.id}`
      );

      loadingVisit.value = true;
      getMessages();
      try {
        const appointment = await http
          .get(`patient-portal/api/appointments/${props.id}`)
          .then((res) => res.data);

        data.appointmentAt = appointment.appointmentAt;
        data.contractorId = appointment.contractorId;
        data.conversationExpiresAt = appointment.conversationExpiresAt;
        data.doctor = appointment.doctor;
        data.isExpired =
          new Date() > new Date(appointment.conversationExpiresAt);
        data.finishedAt = appointment.finishedAt;
        data.id = appointment.id;
      } catch {
        notification.open({
          message: t("ERROR.4955"),
          class: "error",
        });
      } finally {
        loadingVisit.value = false;
      }
    });

    return {
      messages,
      data,
      store,
      addMessageVisible,
      d,
      afterAddMessageModalSuccess,
      loadingMessages,
      loadingVisit,
      t,
    };
  },
});
export default ChatComponent;
